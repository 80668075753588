import React, { useContext, useState } from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Box, Button, FormControl, FormLabel, Modal, Typography } from "@mui/material";
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import "./css/Dashboard.css";
import "./css/Settings.css";
import GaleryAdd from "../../assets/gallery-add.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Input } from "antd";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {EmailOutlined, FacebookOutlined, InsertPageBreakOutlined, Instagram, LinkedIn, LocalPhoneOutlined, SupportAgentOutlined } from "@mui/icons-material";
import { ReactComponent as SupportAgentIcon } from '../../assets/supportAgent.svg';
import { MuiTelInput } from "mui-tel-input";
import { AuthContext } from "../../context/AuthContext";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";

const Dashboard = () => {

  const { user, setUser, userUpdate } = useContext(AuthContext);

  const [tab, setTab] = React.useState(1);
  const [profileImage, setProfileImage] = React.useState(null);
  const [files, setFiles] = useState([]);
  const [tel, setTel] = useState('+22969000000');
  const [updatedUser, setUpdatedUser] = useState(user);

  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleChange = (e) => {
    console.log(updatedUser)
    if (e.target.name === 'profileImage') {
      // If the changed field is 'medias', handle it as a file input
      if (files.length > 1) {
        alert(`maximum 1 images .`);
        return;
      }
      setUpdatedUser(prevState => ({
        ...prevState,
        [e.target.name]: [...(prevState[e.target.name] || []), ...Array.from(e.target.files)]
      }));
      setFiles(user.medias);
    } else {
      // Otherwise, handle it as a normal input
      setUpdatedUser({
        ...updatedUser,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleTelChange = (value, info) => {
    setUpdatedUser( { ...updatedUser, tel : value } )
    console.log(value)
  }

  
  const handleSubmit = () => {
    //userUpdate(updatedUser);
    console.log(updatedUser)  
    toggleModal()
  }

  return (

    <Box
      className="DashboardMain"
      p="4rem"
      paddingTop="3rem"
      sx={{
        backgroundColor: "#F9F9F9",
      }}
      display="grid"
      gridTemplateRows="1rem auto"
      gap="2rem">

      <Box display="grid" gridTemplateRows="5rem auto">
        <Box
          display="grid"
          bgcolor={"white"}
          gridTemplateColumns="55% 37% 10%"
          borderBottom="1px solid #E0E4EC">
          <Box onClick={() => setTab(1)}>
            <Box
              className={tab === 1 ? "TabsHeaderActive" : "TabsHeader"}
              marginLeft="3rem">
              <Typography variant="span" fontSize={16}>
                Paramètres du profil
              </Typography>
            </Box>
          </Box>
          <Box onClick={() => setTab(2)}>
            <Box className={tab === 2 ? "TabsHeaderActive" : "TabsHeader"}>
              <Typography variant="span" fontSize={16}>
                Connexion et sécurité
              </Typography>
            </Box>
          </Box>
          <Box onClick={() => setTab(3)}>
            <Box className={tab === 3 ? "TabsHeaderActive" : "TabsHeader"}>
              <Typography variant="span" fontSize={16}>
                Aide
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box bgcolor={"white"} className={tab === 1 ? "TabsItemActive" : "TabsItem"}>
          <Box
            display="grid"
            gridTemplateColumns="57% auto"
            gap="3rem"
            p="2rem 3rem"
            height="100%">
            {/* // Profile image */}
            <Box display="flex" flexDirection="column">
              <Typography variant="span" color="#9A9EA5">
                Image du profil
              </Typography>
              <Box
                className="profileImageContainer"
                onClick={() =>
                  document.querySelector(".inputProfile").click()
                }>
                <input
                  className="inputProfile"
                  type="file"
                  accept="image/*"
                  hidden
                  name="profileImage"
                  onChange={(e) => {
                    setProfileImage(URL.createObjectURL(e.target.files[0]));
                    
                  }}
                />
                {profileImage ? (
                  <img
                    src={profileImage}
                    width="90%"
                    height="90%"
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <img
                      src={GaleryAdd}
                      alt="Galery Add"
                      width="60%"
                      height="60%"
                    />
                    <Typography variant="h5" color="#9A9EA5">
                      Télécharger l'image du profil
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* // Profile details form */}
            <Box
              display="flex"
              flexDirection="column"
              gap="2rem"
              justifyContent="space-between"
              paddingTop="1rem">
              <FormControl className="inputContainer">
                <FormLabel htmlFor="nom">
                  Nom complet
                </FormLabel>
                <Input
                  id="nom"
                  className="input"
                  type="text"
                  name="nom"
                  value={updatedUser.nom ? updatedUser.nom : ""}
                  onChange={handleChange}
                  placeholder="Veuillez saisir votre nom complet"
                />
              </FormControl>
              <FormControl className="inputContainer">
                <FormLabel htmlFor="identifiant">
                  Identifiant
                </FormLabel>
                <Input
                  id="identifiant"
                  className="input"
                  type="text"
                  name="username"
                  value={updatedUser.username ? updatedUser.username : ""}
                  onChange={handleChange}
                  placeholder="Veuillez saisir votre nom d'utilisateur"
                />
              </FormControl>
              <FormControl className="inputContainer">

                <FormLabel htmlFor="email">E-mail</FormLabel>
                <Input
                  id="email"
                  className="input"
                  type="email"
                  name="email"
                  value={updatedUser.email ? updatedUser.email : ""}
                  onChange={handleChange}
                  placeholder="Veuillez saisir votre email"
                />
              </FormControl>
              <FormControl fullWidth >
                <FormLabel htmlFor="tel" >
                  Numéro de téléphone
                </FormLabel>
                <MuiTelInput 
                sx={{
                  width:'90%'
                }}
                value={updatedUser.tel ? updatedUser.tel : '+229' }
                id="tel" 
                placeholder="Veuillez saisir votre numéro de téléphone"
                forceCallingCode 
                
                defaultCountry={"bj"} 
                onChange={handleTelChange} />

              </FormControl>

              <Box
                display="flex"
                justifyContent="flex-end"
                maxWidth="88%"
                minWidth="350px">
                
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  gap="1rem"
                  borderRadius="5px"
                  bgcolor="#FF3D00"
                  p="0.55rem 2rem"
                  width="fit-content"
                  color="white"
                  justifyContent="center"
                  cursor="pointer"
                  alignItems="center"
                  onClick={handleSubmit} 
                  >
                  <Typography variant="span">Mettre à jour du profil</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box  bgcolor={"white"}  className={tab === 2 ? "TabsItemActive" : "TabsItem"}>
          <Box
            display="flex"
            flexDirection="column"
            p="2rem 5rem"
            gap="2rem"
            width="500px">
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Typography variant="span">Mot de passe</Typography>
              <Input.Password
                placeholder="Veuillez entrer votre mot de passe"
                size="large"
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Typography variant="span">Nouveau mot de passe</Typography>
              <Input.Password
                placeholder="Votre nouveau mot de passe"
                size="large"
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Typography variant="span">Répétez le mot de passe</Typography>
              <Input.Password
                placeholder="Confirmez le nouveau mot de passe"
                size="large"
              />
            </Box>
            <Box
              marginTop="2rem"
              borderRadius="5px"
              bgcolor="#FF3D00"
              p="0.55rem 2rem"
              width="fit-content"
           
              color="white">
              <Typography variant="span" >Mettre à jour</Typography>
            </Box>
          </Box>
        </Box>
        <Box  bgcolor={"white"}  className={tab === 3 ? "TabsItemActive" : "TabsItem"}>
          <Box
            display="flex"
            padding="2rem 5rem"
            justifyContent="space-between"
            color="#DCDCDC"
            height="230px">
            <Box border="1px solid #DCDCDC">
              <Box
                borderBottom="1px solid #DCDCDC"
                p="0.5rem 1.5rem"
                textAlign="center">
                <Typography variant="span" p="1rem" color="rgba(37, 37, 45, 0.54)">
                  Email & appel
                </Typography>
              </Box>
              <Box p="0 1rem">
                <Box
                  display="flex"
                  gap="1rem"
                  marginTop="1rem"
                  color="black">
                  <EmailOutlined fontSize="medium" />
                  <Typography variant="span>" color="#FF3D00">
                    <a href="mailto:hello@wescan.gift?subject=Support%20Dashboard:" target="_blank" rel="noreferrer">hello@wescan.gift</a>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  gap="1rem"
                  marginTop="1.5rem"
                  color="black">
                  <LocalPhoneOutlined fontSize="medium" />
                  <Typography variant="span>" color="#FF3D00">
                   <a href="http://wa.me/+22940538164?text=Support%20Dashboard:" target="_blank" rel="noreferrer">+229 40 53 81 64</a> 
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box paddingBottom="1em" border="1px solid #DCDCDC">
              <Box
                borderBottom="1px solid #DCDCDC"
                p="0.5rem 1.5rem"
                textAlign="center">
                <Typography variant="span" p="1rem" color="rgba(37, 37, 45, 0.54)">
                  Nos réseaux sociaux
                </Typography>
              </Box>
              <Box>
                <Box
                  display="flex"
                  gap="1rem"
                  marginTop="1rem"
                  color="black"
                  justifyContent="center">
                  <FacebookOutlined fontSize="medium" />
                  <Typography variant="span" color="rgba(37, 37, 45, 0.54)">
                   <a href="https://www.facebook.com/wescangift/" target="_blank" rel="noreferrer" >Facebook</a>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  gap="1rem"
                  marginTop="1.5rem"
                  color="black"
                  justifyContent="center">
                  <Instagram fontSize="medium" />
                  <Typography variant="span" color="rgba(37, 37, 45, 0.54)">
                    <a href="https://www.instagram.com/wescangift/" target="_blank" rel="noreferrer" >Instagram</a> 
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  gap="1rem"
                  marginTop="1.5rem"
                  color="black"
                  justifyContent="center">
                  <LinkedIn fontSize="medium" />
                  <Typography variant="span" color="rgba(37, 37, 45, 0.54)">
                     <a href="https://www.linkedin.com/company/wescangift/" target="_blank" rel="noreferrer"> Linkedin</a> 
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box border="1px solid #DCDCDC">
              <Box
                borderBottom="1px solid #DCDCDC"
                p="0.5rem 1.5rem"
                textAlign="center">
                <Typography variant="span" p="1rem" color="rgba(37, 37, 45, 0.54)" >
                  24/7 support client
                </Typography>
              </Box>
              <Box p="0 1rem"
               display="flex"
               gap="1rem"
               marginTop="1.5rem"
               color="black"
               justifyContent="center"
              >
                <SupportAgentIcon />
                
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal open={open} onClose={toggleModal}>
        <Box >
              <Typography variant="span">Nouveau mot de passe</Typography>
              <Input.Password
                placeholder="Votre nouveau mot de passe"
                
              />
          </Box>
      </Modal>
    </Box>

  );
};

export default Dashboard;
