import React, { createContext, useState } from 'react';
import { post } from '../utils/ApiCrud';

// Create the context
export const DashboardContext = createContext();

// Create the context provider
export const DashboardProvider = ({ children }) => {

  const [currentAd, setCurrentAd] = useState();
  const [currentEvent, setCurrentEvent] = useState([]);

  const [newAd, setNewAd] = useState( { nom: '', objectif : null, type_anncs: 2, type_url: null, url_des : null, ids_event: [], limite: null, budget: null, medias : null, date_deb: null, date_fin: null } );

  const [newEvent, setNewEvent] = useState( {} );


  // Add any other state variables or functions here

  //Funtuon to post the ad


  // Return the context provider
  return (
    <DashboardContext.Provider value={{ currentAd, setCurrentAd, currentEvent, setCurrentEvent, newAd, setNewAd, newEvent, setNewEvent }}>
      {children}
    </DashboardContext.Provider>
  );
};
