import React, { useState, useEffect, useContext } from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Box, Button, Input, TextField, Typography } from "@mui/material";
import dayjs from 'dayjs';
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import "./css/Dashboard.css";
import "./css/Settings.css";
import { InputLabel, MenuItem, FormControl, Select, FormLabel } from "@mui/material";
import GaleryAdd from "../../assets/gallery-add.svg";
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import { DashboardContext } from "../../context/DashboardContext";
import { Form } from "react-router-dom";
import { post } from "../../utils/ApiCrud";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { FileToBase64 } from "../../utils/function";
import client from "../../utils/axiosClient";

/**
 * Renders a component for creating new announcements.
 * 
 * @returns {JSX.Element} The NewAnnonces component.
 */
const NewAnnonces = () => {

  const { newAd, setNewAd } = useContext(DashboardContext);
  const [files, setFiles] = useState([]);


  const [tab, setTab] = React.useState(1);


  const [dragging, setDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      setFiles(prev => [...prev, ...event.dataTransfer.files]);
      event.dataTransfer.clearData();
    }
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e) => {
    
    setFiles(
      e.target.files
   );
    console.log(files);
  };


  const handleChange = (e) => {
    if (e.target.name === 'medias') {
      // If the changed field is 'medias', handle it as a file input
      if (files.length > 3) {
        alert(`maximum 3 images .`);
        return;
      }
      setNewAd(prevState => ({
        ...prevState,
        [e.target.name]: [...(prevState[e.target.name] || []), ...Array.from(e.target.files)]
      }));
      setFiles(newAd.medias);
    } else {
      // Otherwise, handle it as a normal input
      setNewAd({
        ...newAd,
        [e.target.name]: e.target.value
      });
    }
  };
  // fonction pour envoyer une annonce
const handleSubmit = () => {
  let updatedAd = { ...newAd };

  if (updatedAd.type_anncs === 2 && !updatedAd.budget) {
    alert("Veuillez renseigner le budget de l'annonce");
    return;
  }

  if (updatedAd.type_anncs === 1 && !updatedAd.limite) { 
    alert("Veuillez renseigner le nombre de cadeaux à partager");
    return;
  }

  if (!updatedAd.type_anncs || !updatedAd.nom || !updatedAd.ids_event || !updatedAd.type_url || !updatedAd.objectif || !updatedAd.date_deb || !updatedAd.date_fin || !updatedAd.url_des || !updatedAd.medias) {
    alert("Veuillez renseigner tous les champs");
    return;
  }

  if (updatedAd.type_anncs === 2) {
    updatedAd.limite = Math.floor(updatedAd.budget / 25);
  }
  if (updatedAd.medias.length > 3 || files.length > 3 ){
    alert("Vous ne pouvez envoyer que 3 images maximum par annonce.");
    return;
  }

  // Create a FormData object
  let formData = new FormData();

  // Add the fields of the updatedAd object to the FormData object
  for (let key in updatedAd) {

    // If the field is an array, append it as a JSON string
    if ( key === 'ids_event' ) {
      formData.append(key, JSON.stringify(updatedAd[key]) );
    }
    else if (key === 'medias') {
      // If the field is an array, append it as is
      for (let i = 0; i < updatedAd[key].length; i++) {
        formData.append(key, updatedAd[key][i]);
      }
    }
     else {
      // If the field is not an array, append it as is
      formData.append(key, updatedAd[key]);
    }
  }


  console.log(formData);
  console.log(updatedAd);
  const id = 1;

  //const response = post(`/upload/annonceur/${id}/annonce`, formData, { 'Content-Type': 'multipart/form-data' })
  const response = client.post(`/upload/annonceur/${id}/annonce`,
  formData,
   {  headers : { 'Content-Type' : 'multipart/form-data' , }
  })
  .then((response) => {
      console.log(response);
      if (response.data.success) {
        console.log(formData);
        alert("Annonce créée avec succès");
        newAd = null // vider le formulaire
        // rediriger vers la page des annonces
      } else {
        alert("Erreur lors de la création de l'annonce. Vérifiez les informations renseignées.");
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert("Erreur lors de la création de l'annonce");
    });

   
}

// const handleSubmitForm = (e) => { 
//   e.preventDefault();
//   console.log(e);
// }
  return (
    <Box
      className="DashboardMain"
      p="4rem"
      sx={{
        backgroundColor: "#F9F9F9",
      }}
      display="grid"
      gridTemplateRows="1rem auto"
      gap="2rem">

      <Box bgcolor={"#fff"} display="grid" gridTemplateRows="5rem auto">
        <Box
          bgcolor={"#fff"}
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #E0E4EC">
          <Box onClick={() => setTab(1)}>
            <Box
              className={tab === 1 ? "TabsHeaderActive" : "TabsHeader"}
              marginLeft="3rem">
              <Typography variant="span" fontSize={16}>
                Ajouter une annonce
              </Typography>
            </Box>
          </Box>
          <Box onClick={() => setTab(2)}>
            <Box
              className={tab === 2 ? "TabsHeaderActive" : "TabsHeader"}
              marginLeft="3rem">
              <Typography variant="span" fontSize={16}>
                Aide
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor={"#fff"}
          display="flex"
          justifyContent="space-between"
          paddingRight="2rem"
          paddingBottom="4em"
          gap="1rem">
          {/* côté gauche du formulaire d'ajour de l'annonce */}
          <Box width="45%" maxWidth="500px">
            {/* <form onSubmit={handleSubmitForm} > */}
           
            <Box
              paddingTop="4rem"
              paddingLeft="2rem"
              display="flex"
              flexDirection="column"
              gap="1.5rem">
              <Box width="90%" >
                <InputLabel htmlFor="nom">Nom de l'annonce</InputLabel>
                <Input
                  style={{ width: "100%" }}
                  className="input"
                  name="nom"
                  id="nom"
                  type="text"
                  value={newAd.nom}
                  placeholder="Nom de l'annonce"
                  onChange={handleChange}
                />
              </Box>
              <Box width="90%">
                <FormControl fullWidth>
                  <FormLabel id="event-label" htmlFor="ids_event" >Choix des bornes</FormLabel>
                  <Select
                    labelId="event-label"
                    id="ids_event"
                    label="Borne"
                    name="ids_event"
                    displayEmpty
                    multiple
                    value={newAd.ids_event ? newAd.ids_event : []}
                    onChange={ (e) => setNewAd( { ...newAd, ids_event:   typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value, })}>

                    <MenuItem value={"1"}>Fruitizz - Missebo</MenuItem>
                    <MenuItem value={"2"}>Seme city</MenuItem>
                    <MenuItem value={"3"}>Iroko Lab</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="90%">
                <Box width="45%">
                  <FormControl sx={{ minWidth: '100%' }} >
                    <FormLabel id="TypeLabel" htmlFor="type_anncs" >Type d'annonce</FormLabel>
                    <Select
                      labelId="typeLabel"
                      id="type_anncs"
                      name="type_anncs"
                      value={newAd.type_anncs}
                      label="Type d'annonce"
                      onChange={handleChange}
                      autoWidth
                      defaultValue={2}
                    >
                      <MenuItem selected value={1}>Cadeau</MenuItem>
                      <MenuItem value={2}>Publicité</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box width="50%" >
                  {
                    newAd.type_anncs === 2 ?
                      <FormControl sx={{ minWidth: '100%' }} >
                        <FormLabel htmlFor="budget">Budget</FormLabel>
                        <Input
                          style={{ width: "100%", borderRadius: "5px", marginTop: "0" }}
                          className="input"
                          name="budget"
                          id="budget"
                          type="number"
                          min="0"
                          value={newAd.budget}
                          placeholder="Budget d'annonce"
                          onChange={handleChange}
                        />
                      </FormControl> :
                      <FormControl sx={{ minWidth: '100%' }} >
                        <FormLabel htmlFor="limite">Nombre à partager</FormLabel>
                        <Input
                          style={{ width: "100%", borderRadius: "5px", marginTop: "0" }}
                          className="input"
                          name="limite"
                          id="limite"
                          type="number"
                          min="0"
                          value={newAd.limite}
                          placeholder="Nombre de cadeaux"
                          onChange={handleChange}
                        />
                      </FormControl>
                  }
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="90%">
                <Box width="45%">
                  <FormControl sx={{ minWidth: '100%' }} >
                    <FormLabel id="type_url">Type de destination</FormLabel>
                    <Select
                      labelId="type_url"
                      id="type_url"
                      name="type_url"
                      value={newAd.type_url}
                      label="Type du lien"
                      onChange={handleChange}>
                      <MenuItem value={4}>Site web</MenuItem>
                      <MenuItem value={3}>WhatsApp</MenuItem>
                      <MenuItem value={2}>Réseaux sociaux</MenuItem>
                      <MenuItem value={1}>Autre</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box width="50%" >
                  <FormControl sx={{ minWidth: '100%' }} >
                    <FormLabel id="TypeLabel">Objectif</FormLabel>
                    <Select
                      labelId="objectif"
                      id="objectif"
                      value={newAd.objectif}
                      label="Objectif"
                      onChange={handleChange}
                      autoWidth
                      name="objectif"
                    >
                      <MenuItem selected value={6}>Traffic</MenuItem>
                      <MenuItem value={2}>WhatsApp</MenuItem>
                      <MenuItem value={3}>Téléchargement</MenuItem>
                      <MenuItem value={4}>Appel</MenuItem>
                      <MenuItem value={5}>Abonnement</MenuItem>
                      <MenuItem value={1}>Autre</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {
                newAd.type_url === 3 ?
                  <Box>
                    <Box className="inputContainer">
                      <input
                        className="input"
                        type="tel"
                        value={newAd.whatsapp}
                        placeholder="Numéro si lien whatsapp"
                      />
                    </Box>
                    <Box className="inputContainer">
                      <input
                        className="input"
                        type="text"
                        placeholder="Message d’appel à l’action"
                        value={newAd.message}
                      />
                    </Box>
                  </Box>
                  :
                  <Box className="inputContainer">
                    <InputLabel id="url_des_label" htmlFor="url_des" >URL de destination</InputLabel>
                    <Input
                      className="input"
                      type="url"
                      name="url_des"
                      placeholder="https://www.example.com"
                      value={newAd.url_des}
                      onChange={handleChange}
                    />
                  </Box>
              }
            </Box>
            {/* <input type="submit" value="valider" />
            </form> */}
          </Box>
          {/* côté droit du formulaire d'ajour de l'annonce, images et dates */}
          <Box
           
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="50%"
            gap="1.5rem">

            <Box
              display="flex"
              flexDirection="column"
              paddingTop="2rem"
              height="100%"
              width={"100%"}
              justifyContent="center">
              <Box
                className={`profileImageContainer ${dragging ? 'dragging' : ''}`}
                onClick={() => document.querySelector(".inputImageAd").click()}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
              >
                <input
                  className="inputImageAd"
                  type="file"
                  accept="image/*"
                  name="medias"
                  hidden
                  onChange={(e) => { handleChange(e); handleFileChange(e) } }
                />
                {files.length > 0 ? (
                  <div className="row">
                    {Array.from(files).map((image, index) => (
                      <div className="col-4" key={index}>
                        <img className="img-fluid img-thumbnail" src={URL.createObjectURL(image)} alt="Preview" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={GaleryAdd}
                      alt="Galery Add"
                      width="60%"
                      height="60%"
                    />
                    <Typography variant="h5" color="#9A9EA5">
                      Cliquez pour ajouter l'image
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="70%"
              maxWidth="500px">
               
              <Box className="mx-2" width="90%">
                <FormControl fullWidth>
                  <FormLabel id="date_debut_label" htmlFor="date_deb" >Date de debut</FormLabel>
                  <DatePicker 
                  minDate={dayjs()}
                  name="date_deb"
                  id="date_deb"
                  value={newAd.date_deb}
                  onChange={ (date) => setNewAd({...newAd, date_deb: date }) }
                  />
                </FormControl>
              </Box>
              <Box className="mx-2" width="90%">
                <FormControl fullWidth>
                  <FormLabel id="date_fin_label" htmlFor="date_fin" >Date de fin</FormLabel>
                  <DatePicker 
                    value={newAd.date_fin}
                    name="date_fin" 
                    minDate={newAd.date_deb ? dayjs(newAd.date_deb).add(1, 'day') : dayjs().add(1, 'day')}
                    id="date_fin" 
                    onChange={(date) => setNewAd({...newAd, date_fin: date})}
                    />
                </FormControl>
              
              </Box>
            </Box>
            <Box
              sx={{ cursor: "pointer", width: "18em" }}
              borderRadius="5px"
              bgcolor="#FF3D00"
              p="0.25rem 2rem"
              width="fit-content"
              color="white"
              onClick={handleSubmit}
              >
              <Typography variant="h6" className="text-center py-1" fontSize={13}>
                Publier annonce
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewAnnonces;
