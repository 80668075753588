import React, { useEffect } from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Box, Button, Typography, ButtonGroup } from "@mui/material";
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import { Tables } from "../../data/table";
import Table from "../global/Table";
import { Link, useLoaderData } from "react-router-dom";
import client from "../../utils/axiosClient";


const Evenements = () => {

  const events = useLoaderData();

  useEffect(() => {
    console.log(events);
  }, [events]);

  const maxNumber = 9;
  return (
        <Box
          className="DashboardMain"
          p="4rem"
          paddingTop="3rem"
          sx={{
            backgroundColor: "#F9F9F9",
          }}
          display="grid"
          gridTemplateRows="2rem auto"
          gap="2rem">
          <Box>
            {/* <Link to="/evenements/new">
              <Box
                borderRadius="5px"
                bgcolor="#FF3D00"
                p="0.25rem 2rem"
                width="fit-content"
                color="white">
                <Typography variant="h6" fontSize={13}>
                  Créer un événement
                </Typography>
              </Box>
            </Link> */}

<ButtonGroup variant="contained" style={{
            height: '100%',
          }}
            aria-label="outlined primary button group">
            <Link to="/evenements/new"> <Button target='_blank' >  Créer un événement </Button> </Link>
            <Link to="/bientot">  <Button style={{ background: '#EFEFFD' }} className='h-100 text-primary'  > Performance événements </Button></Link>
            <Link to="/annonces/new">  <Button style={{ background: '#EFEFFD' }} className='h-100 text-primary'  > Créer une annonce </Button></Link>

          </ButtonGroup>
          </Box>
          <Box className="TableContainer" height="100%">
            <Table data={events.data} type="Evenements" maxNumber={maxNumber} />
          </Box>
        </Box>
   );
};

export default Evenements;

export const evenementsLoader = async () => {

  var data
  const id = 8 ;
      try {
        const response = await  client.get(`/annonceur/${id}/statistiques/events`);
        const jsonData = response;
        data = jsonData.data;
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        data = error.response;
      }

  return data
};
