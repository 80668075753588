import React, { useState, useEffect, useContext, useRef } from "react"; import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { DashboardContext } from "../../context/DashboardContext";
import Modal from 'react-modal';
import { Notifications } from "../../data/notifs";
import "./css/Dashboard.css";
import "./css/Settings.css";
import { Box, Button, Input, TextField, Typography, InputLabel, MenuItem, FormControl, Select, FormLabel, Tooltip, Popper, ClickAwayListener } from "@mui/material";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { post, patch } from "../../utils/ApiCrud";
import { MuiTelInput } from "mui-tel-input";
import { dataURLtoBlob, fsImageAsDataURI, getQueryParams, payWithQosicCheckout } from "../../utils/function";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router";
import EasyQRCode from 'easyqrcodejs';

//import ReactAwesomeQR from "react-awesome-qr";
import logoSrc from "../../assets/logo192.png";

const NewEventPage = () => {
  const [tab, setTab] = React.useState(1);

  const { newEvent, setNewEvent } = useContext(DashboardContext);

  const [eventBorneImg, setEventBorneImg] = useState(null);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const idUser = localStorage.getItem('userId');

  const [updatedUser, setUpdatedUser] = useState(user);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);



  const openToolTip = Boolean(anchorEl);
  const idtoolTip = openToolTip ? 'simple-popper' : undefined;

  const openInfo = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const closeInfo = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };


  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const paymentId = queryParams['paymentId'];
  const paymentStatus = queryParams['paymentStatus'];

  useEffect(() => {

    if (paymentStatus !== "success" && paymentId) {
      setModalIsOpen(true);
    }
  }, [paymentStatus, paymentId]);

  // si le paiement est fait, faire la requête pour enregistrer l'achat et créer la borne
  useEffect(() => {

    if (paymentId && paymentStatus === 'success') {
      console.log(paymentStatus, paymentId);
      post(`/annonceur/${idUser}/abonnement`, { 'id_services': newEvent.type_master_code, 'payment_id': paymentId })
        .then((res) => {
          console.log(res);
          setNewEvent(
            res.data.data
          )
        }).catch((err) => {
          console.log(err);
        })

    } else if (paymentStatus === 'failed' || paymentStatus === 'cancelled') {
      console.log("payment failed");
      alert("Paiement échoué. Veuillez réessayer.");
    }
  }, [paymentStatus, paymentId]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setNewEvent({
      ...newEvent,
      [e.target.name]: [e.target.value]
    });
  };

  const handleTelChange = (value, info) => {
    setUpdatedUser({ ...updatedUser, tel: value })
    console.log(value)
  }

  const initiatePayment = () => {

    // payWithQosicCheckout( { nom : user.nom, amount : amount,  } );

    console.log(newEvent);
    post(`/annonceur/${idUser}/initiateBornePayment`, { 'id_services': newEvent.type_master_code, 'usertel': updatedUser.tel })
      .then((res) => {
        console.log(res.data);

        //redirect to payment page
        window.open(res.data.data.checkout_url);

      }).catch((err) => {
        console.log(err);
      })
  }

  const qrCodeRef = useRef(null);
  const handleNewEvent = () => {


    let options = {
      text: `https://wescan.gift/${newEvent.nom}`, // Content
      logo: logoSrc, // Path to the logo image
      logoWidth: 100, // Width of the logo
      logoHeight: 100, // Height of the logo
      logoBackgroundColor: '#ffffff', // Logo background color
      logoBackgroundTransparent: true, // Whether to make the logo background transparent
      format: 'PNG', // Image format
      width: 512, // Width
      height: 512, // Height
      colorDark: "#000000", // QR Code color
      colorLight: "#ffffff", // Background color
      PO: "#ff3d33", // Outer eye color
      PI: "#0e61cb", // Inner eye color

    };

    // Clear the previous QR code
    qrCodeRef.current.innerHTML = '';


    const qrcode = new EasyQRCode(qrCodeRef.current, options);

    setEventBorneImg(qrcode);

    const dataUrl = qrCodeRef.current.querySelector('canvas').toDataURL();
    const blob = dataURLtoBlob(dataUrl);
    const file = new File([blob], 'qr_code.png', { type: 'image/png' });
    console.log(file);

    patch(`/upload/annonceur/${idUser}/event/23`, { ...newEvent, qr_code: file }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        console.log(res);
        if (!res.success) {
          alert("Une erreur s'est produite lors de la création de la borne. Veuillez réessayer.");
        } else {
          alert("Borne créée avec succès");
          setQrCodeGenerated(true);

          // navigate('/evenements', { replace: true });
        }

      }).catch((err) => {
        console.log(err);
      });

    console.log(newEvent, 'newEvent');
  }


  const handleDownloadQRCode = () => {


    // htmlToImage.toPng(qrCodeRef.current.querySelector('canvas'))
    //   .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'my-image-name.png';
    //     link.href= dataUrl;
    //     link.click(); // This will download the image}
    //   }
    //   )
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });

    eventBorneImg.download('wsq_qrcode')

  }


  return (
    <Box
      className="DashboardMain"
      p="4rem"
      sx={{
        backgroundColor: "#F9F9F9",
      }}
      display="grid"
      gridTemplateRows="1rem auto"
      gap="2rem">

      <Box bgcolor="white" display="grid" gridTemplateRows="5rem auto">
        <Box
          bgcolor="white"
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #E0E4EC">
          <Box onClick={() => setTab(1)}>
            <Box
              className={tab === 1 ? "TabsHeaderActive" : "TabsHeader"}
              marginLeft="3rem">
              <Typography variant="span" fontSize={16}>
                Créer une borne à cadeaux privée
              </Typography>
              <i className="ms-2 fas fa-info-circle" aria-describedby={idtoolTip} type="button" onClick={openInfo} ></i>
            </Box>
          </Box>
          <Box onClick={() => setTab(2)}>
            <Box
              className={tab === 2 ? "TabsHeaderActive" : "TabsHeader"}
              marginLeft="3rem">
              <Typography variant="span" fontSize={16}>
                Aide
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* paiement de la borne avec envoie de numéro de téléphone */}

        { // si le paiement est réussi alors ne plus afficher le formulaire de paiement
          paymentId && paymentStatus === 'success' ? null :
            <Box bgcolor="white" width="100%" paddingBottom="4rem" >
              <Box width="50%" maxWidth="500px">
                <Box
                  paddingTop="4rem"
                  paddingLeft="2rem"
                  display="flex"
                  flexDirection="column"
                  gap="1.5rem">

                  <Box >
                    <FormControl fullWidth>
                      <InputLabel id="label_type_master_code">
                        Type master code
                      </InputLabel>
                      <Select
                        labelId="label_type_master_code"
                        id="type_master_code"
                        value={newEvent.type_master_code ? newEvent.type_master_code : [4]}
                        label="Type master code"
                        name="type_master_code"
                        onChange={handleChange}>

                        <MenuItem value={4} disabled >Type master code</MenuItem>
                        <MenuItem value={1} >Master Code 1</MenuItem>
                        <MenuItem value={2}>Master Code 2</MenuItem>
                        <MenuItem value={3}>Enterprise (Borne sur mesure)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box >
                    <FormControl fullWidth >

                      <MuiTelInput

                        value={updatedUser.tel ? updatedUser.tel : '+229'}
                        id="tel"
                        placeholder="Veuillez saisir votre numéro de téléphone"
                        forceCallingCode
                        name="tel"
                        defaultCountry={"bj"}
                        onChange={handleTelChange} />

                    </FormControl>
                  </Box>
                  <Box
                    sx={{ cursor: "pointer", width: "100%" }}
                    borderRadius="5px"
                    bgcolor="#FF3D00"
                    p="0.25rem 2rem"
                    color="white"
                    onClick={initiatePayment}
                    className="text-center"
                  >
                    <Typography variant="h6" className="text-center py-1" fontSize={18}>
                      Commander
                    </Typography>

                  </Box>
                  <p> A payer : {newEvent.type_master_code === 1 ? "30.000 F CFA" : newEvent.type_master_code === 2 ? "50.000 F CFA" : ""} </p>
                </Box>
              </Box>

            </Box>
        }
        {/*  Configuration de la borne/evenement  
       Si le paiement est effectué et que le status est success alors on affiche le formulaire de configuration de la borne 
       */}
     {   
        paymentId && paymentStatus === 'success' ? 
        <Box
          bgcolor="white" width="100%" paddingBottom="4rem" display="flex" justifyContent="space-evenly" >
          <Box width="45%" maxWidth="500px">
            <Box
              paddingTop="4rem"
              paddingLeft="2rem"
              display="flex"
              flexDirection="column"
              gap="1.5rem">
              <Box className="inputContainer">
                <FormLabel htmlFor="event_name">Nom de la borne</FormLabel>
                <TextField fullWidth
                  id="event_name"
                  name="nom"
                  value={newEvent.nom ? newEvent.nom : ""}
                  placeholder="Ex : Borne de noël"
                  helperText="Code unique de votre borne"
                  onChange={(e) => { setNewEvent({ ...newEvent, nom: e.target.value }); console.log(e.target.value) }}
                />
              </Box>

              <Box className="inputContainer">
                <FormLabel htmlFor="adresse">Lieu (adresse de la borne)</FormLabel>
                <TextField fullWidth
                  id="adresse"
                  name="adresse"
                  value={newEvent.adresse ? newEvent.adresse : ""}
                  placeholder="Ex : mon restaurant, mon magasin, etc."
                  helperText="Lieu où sera la borne de cadeaux"
                  onChange={(e) => setNewEvent({ ...newEvent, adresse: e.target.value })}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Box >
                  <FormControl fullWidth>
                    <FormLabel id="date_debut_label" htmlFor="date_deb" >Date de debut</FormLabel>
                    <DatePicker
                      minDate={dayjs()}
                      name="date_deb"
                      id="date_deb"
                      disabled
                      value={newEvent.date_deb ? newEvent.date_deb : dayjs()}
                      onChange={(date) => { setNewEvent({ ...newEvent, date_deb: date }); console.log(date) }}
                    />
                    <span>Dates par défaut. Laissez vid.</span>
                  </FormControl>
                </Box>

                <Box >
                  <FormControl fullWidth>
                    <FormLabel id="date_fin_label" htmlFor="date_fin" >Date de fin</FormLabel>
                    <DatePicker
                      disabled
                      value={newEvent.date_fin ? newEvent.date_fin : dayjs().add(30, 'day')}
                      name="date_fin"
                      minDate={newEvent.date_deb ? dayjs(newEvent.date_deb).add(30, 'day') : dayjs().add(1, 'day')}
                      id="date_fin"
                      onChange={(date) => setNewEvent({ ...newEvent, date_fin: date })}
                    />
                  </FormControl>

                </Box>
              </Box>

              {
                qrCodeGenerated ? <Box
                  sx={{ cursor: "pointer", width: "100%" }}
                  borderRadius="5px"
                  bgcolor="#FF3D00"
                  p="0.25rem 2rem"
                  color="white"
                  onClick={handleDownloadQRCode}
                  className="text-center"
                >
                  <Typography variant="h6" className="text-center py-1" fontSize={18}>
                    Télécharger le QR code
                  </Typography>
                </Box> :

                  <Box >
                    <Typography variant="h4" className="text-center mb-3" style={{ color: '#ff0000' }}>
                      ATTENTION CE CODE NE SERA PLUS MODIFIABLE !!!
                    </Typography>
                    <Box
                      sx={{ cursor: "pointer", width: "100%" }}
                      borderRadius="5px"
                      bgcolor="#FF3D00"
                      p="0.25rem 2rem"
                      color="white"
                      onClick={handleNewEvent}
                      className="text-center"
                    >
                      <Typography variant="h6" className="text-center py-1" fontSize={18}>
                        Créer ma borne
                      </Typography>
                    </Box>
                  </Box>

              }
            </Box>
          </Box>

          <Box width="45%" maxWidth="500px">
            <Box
              paddingTop="1rem"
              width="500px"
              height="500px"
              paddingLeft="2rem"
              display="flex"
              flexDirection="column"
              gap="1.5rem">

              {/* <QRCode
                value={newEvent.nom ? newEvent.nom : "Borne de noël"}
                size={512}
                fgColor='#000000' // Couleur des coins (eye)
                imageSettings={{
                  src: logoSrc,
                  height: 100,
                  width: 100,
                }}
              /> */}


              <div ref={qrCodeRef} />

            </Box>
          </Box>
        </Box>
         : null
           
          } 


        {/* Si le paiement est initié mais le résultat n'est pas autre que success alors on affiche le modal d'erreur   */}
        <Modal
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.35)' // This will give a dark background with 75% opacity
            },
            content: {
              // color: 'lightsteelblue', // This will change the text color
              width: '50%', // This will change the width of the modal
              // height: '50%', // This will change the height of the modal
              marginLeft: 'auto', // These two lines will center the modal
              marginRight: 'auto',
              borderRadius: '10px', // This will round the corners of the modal
              padding: '20px' // This will add padding inside the modal
            }
          }}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(!modalIsOpen)}
          shouldCloseOnOverlayClick={true} // This allows the modal to close when clicking outside
        >
          <Box
            bgcolor="white"
            height="100%"
            paddingBottom="4rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 mb={2}> Oops 🫢! </h1> {/* Add space below the h1 */}
            <Typography
              variant="p"
              fontSize={24}
              className="text-center"
              mb={2}>
              Il semble y avoir eu un échec lors du paiement. Veuillez vérifier les informations et réessayer.
            </Typography> {/* Add space below the Typography */}

            <Box
              sx={{ cursor: "pointer" }}
              borderRadius="5px"
              bgcolor="#0E61CB"
              p="0.25rem 2rem"
              color="white"
              onClick={() => {
                setModalIsOpen(!modalIsOpen);
                navigate('/evenements/new', { replace: true });

              }}
              className="text-center"
            >
              <Typography variant="h6" className="text-center py-1" fontSize={18}>
                Okay
              </Typography>
            </Box>
          </Box>

        </Modal>

        <ClickAwayListener onClickAway={closeInfo}>
          <Popper id={idtoolTip} open={openToolTip} anchorEl={anchorEl} placement="right">
            <Box backgroundColor="#fff" className="p-2 border border-dark rounded" >
              <Typography sx={{ p: 2, width: '500px' }}>Une borne privé vous sert personnellement. Seule les annonces que vous y ajoutées seront affichées.
                En apprendre plus dans la page d'aide.</Typography>
            </Box>
          </Popper>
        </ClickAwayListener>
      </Box>
    </Box>


  );
};

export default NewEventPage;
