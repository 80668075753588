import React from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Link } from "react-router-dom";
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/core';
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import "./css/Dashboard.css";
import dayjs from "dayjs";
/* import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar"; */

const Soon = () => {
  return (
        <Box
          className="DashboardMain"
          p="4rem"
          paddingTop="3rem"
          sx={{
            backgroundColor: "#F9F9F9",
          }}
          display="grid"
          gridTemplateRows="14rem 8rem auto"
          gap="2rem">
          <Box display="flex" bgcolor="#ff3d00" borderRadius="15px">
            <Box
              id="vecteurBox1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="80%"
              borderRight="1px solid white">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                color="white">
                {/* <Typography variant="h4" fontSize="1.5rem">
                  Dépensé ce mois
                </Typography> */}
                <Typography variant="span" fontSize="4rem">
                  Merci
                </Typography>
                {/* <Typography variant="span" fontSize="1rem">
                  CFA
                </Typography> */}
              </Box>
            </Box>
            <Box
              id="vecteurBox2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="80%">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                color="white">
                {/* <Typography variant="h4" fontSize="1.5rem">
                  Crédit restant
                </Typography> */}
                <Typography variant="span" fontSize="4rem">
                  Beaucoup 🥰 !
                </Typography>
                {/* <Typography variant="span" fontSize="1rem">
                  CFA
                </Typography> */}
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            bgcolor="#7CA1D2"
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="white">
            <Typography variant="h4" fontWeight="bold" fontSize="1.5rem">
              Beaucoup de fonctionnalités sont à venir !
            </Typography>

<Button 
  component={Link} 
  to="https://google.com" 
  target="_blank" 
  variant="contained" 
  className="px-4 py-2 mt-4"
  style={{ backgroundColor: 'white', color: 'black', fontWeight: 'bold'}}
>
  Proposer une fonctionnalité
</Button>
          </Box>
          <Box bgcolor="white" >

           
          <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Liste de fonctionnalités</TableCell>
        <TableCell align="right">État</TableCell>
        <TableCell align="right">Commentaire</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {/* Replace this with your data */}
      <TableRow>
        <TableCell component="th" scope="row">
          Performance des événements
        </TableCell>
        <TableCell align="right">En cours</TableCell>
        <TableCell align="right">Aucun commentaire</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          Performance des annonces
        </TableCell>
        <TableCell align="right">En cours</TableCell>
        <TableCell align="right">Aucun commentaire</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          Retargeting des prospects
        </TableCell>
        <TableCell align="right">En cours</TableCell>
        <TableCell align="right">Aucun commentaire</TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          Lieu de scanne de vos annonces
        </TableCell>
        <TableCell align="right">En cours</TableCell>
        <TableCell align="right">Aucun commentaire</TableCell>
      </TableRow>
      {/* End replace */}
    </TableBody>
  </Table>
</TableContainer>

        </Box>
        </Box>
  );
};

export default Soon;
