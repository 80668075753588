import {useState, createContext } from "react";
import client from "../utils/axiosClient";
import { Navigate, useNavigate } from "react-router";
import axios from "axios";
import Register from "../utils/auth/Register";
import Login from "../utils/auth/Login";
import { patch } from "../utils/ApiCrud";



export const AuthContext = createContext()

export function AuthProvider({ children }) {

    const [currentStep, setStep] = useState(1);
    const [user, setUser] = useState( localStorage.getItem('userData') !== null ? JSON.parse(localStorage.getItem('userData')) : {});
    const [selectedPreferences, setSelectedPreferences] = useState([]);
    const [finalData, setFinalData] = useState( localStorage.getItem('userData') !== null ? JSON.parse(localStorage.getItem('userData')) : {} );
    //const [finalData, setFinalData] = useState({});

    const [isLogin, setIsLogin] = useState( localStorage.getItem('userId') ? true : false);
    const [reqResult, setreqResult] = useState();

    const prefList = ['Vêtements', 'Décoration', 'Jeux', 'Jouets', 'Evénements', 'Beauté & soins','Sport', 'Electronique', 'Formation','Fournitures', 'Repas et besoins']
   
    // Définissez des fonctions pour gérer l'authentification ici
    function SubmitData(user) {
      
      setFinalData({...user, 'preferences' : selectedPreferences.toString() } )
      Register( {...user, 'preferences' : selectedPreferences.toString() } )
      localStorage.setItem('userData', JSON.stringify(finalData) )

    }

    const usernameAvailable = async () => {

    }

  /**
   * Fonction de connexion de l'utilisateur
   * @param {object} user 
   */
   const userLogin = async (user) =>{

    // certainement mettre la requête ici 
    const data = {
      username: user.username,
      mdp: user.mdp,
  }

  await client.post('/annonceur/login', data , {
      headers: {
          'Content-Type': 'application/json'
      },
  })
      .then((resp) => {
          var res

          if (!resp.data.succes) {
              alert(resp.data.msg);
              res = false
          } else {
              //👇🏻 save the number to the local storage

              setFinalData(resp.data.data.user)
              setUser(resp.data.data.user)
              setIsLogin(true)
              localStorage.setItem('userId', resp.data.data.user.id)
              localStorage.setItem('token', resp.data.data.token)
              localStorage.setItem('userData', JSON.stringify(resp.data.data.user))
              res = true
          }
          return res
      })
      .catch(error => {
          console.error(error);
          if (error.response && error.response.data.error) {
              alert(error.response.data.msg);
          }
          return { success: false };
      })
   }

   /**
    * Mise à jour de l'utilisateur
    * @param {} user 
    */
   const userUpdate = (updatedUser) => {

   patch("/annonceur", updatedUser)
   .then((res) => {   
    setUser(updatedUser)
    console.log('Updated user :' + res.data)
   } )
   .catch((error) => {
    console.error('User not updated :' + error)
   })

    console.log('update user : ' + updatedUser)
   }
  


  // Fonction de déconnexion de l'utilisateur
  const logout = () => {
    //Logique de déconnexion...
 
    setUser();
    setSelectedPreferences([])
    setFinalData({})
    localStorage.removeItem('userId')
    localStorage.removeItem('userData')
    localStorage.removeItem('token')


    setIsLogin(false) 
    console.log('Déconnecté')
  };

  const handlePreferenceClick = (preference) => { 
       
    // Vérifie si la préférence est déjà sélectionnée
    const isAlreadySelected = selectedPreferences.includes(preference);

    if (isAlreadySelected) {
      // Si déjà sélectionnée, la retire du tableau des préférences
      setSelectedPreferences(selectedPreferences.filter((item) => item !== preference));
    } else {
      // Si non sélectionnée, l'ajoute au tableau des préférences
      setSelectedPreferences([...selectedPreferences, preference]);
    }
    

  };

  // const isPreferenceSelected = (preference) => {
  //   return selectedPreferences.includes(preference);    
  // };
  const isPreferenceSelected = (preference) => {
    if (!selectedPreferences || selectedPreferences.length === 0) {
        return false;
    }
    return selectedPreferences.includes(preference);    
};
  
    return (
      <AuthContext.Provider value={{currentStep, setStep, user, setUser, finalData, selectedPreferences, setSelectedPreferences, setFinalData, SubmitData, reqResult, userLogin, isLogin, setIsLogin,logout, prefList, handlePreferenceClick,isPreferenceSelected, userUpdate  }}>
        {children}
      </AuthContext.Provider>
    );
  }
  