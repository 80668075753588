import client from "./axiosClient";

// Function to perform a GET request
export const get = async(url) =>{
    try {
        
        const response = await client.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return error.response.data
    }
}

// Function to perform a POST request
export const post = async (url, body, headers = { "Content-Type" : "application/json" } ) => {
    try {
        const response = await client.post(url, body, headers);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        console.log(JSON.stringify(error.response));
        throw error;
    }
}

// Function to perform a PACTH request
export const patch = async (url, body, headers) => {
    try {
        const response = await client.patch(url, body, headers );
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// Function to perform a DELETE request
export const del = async (url) => {
    try {
        const response = await client.delete(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}