export const Filters = [
  {
    filter: "7 derniers jours",
  },
  {
    filter: "14 derniers jours",
  },
  {
    filter: "30 derniers jours",
  },
  {
    filter: "90 derniers jours",
  },
];
