import { useNavigate } from "react-router-dom";
import client from "../axiosClient";
import {cleanPhone} from "../function";


// const Register = (user) => {

//     const reqBody = {
//         username: user.username,
//         mdp: user.mdp,
//         nom: user.fullname,
//         email: user.email,
//         tel: removePlusSign(user.tel),
//         preferences: user.preferences
//     }

//     let res = {}

//     client.post("/annonceur", reqBody,
//         {
//             headers: {
//                 "Content-Type": "application/json",
//             }
//         })
//         .then((resp) => {
//             console.log(resp.data)
//             if (resp.data.error) {
//                 alert(resp.data.msg);
//                 return { success: false }

//             } else {
//                 //👇🏻 Logs the username to the console
//                 console.log(resp.data.data);
//                 //👇🏻 save the username to the local storage
//                 localStorage.setItem("userId", resp.data.data.id);
//                 return { success : true , data: resp.data.data}
//             }
           
//         })
//         .catch(err => { 
//             console.error(err);
         
//             if (err.response.data.error) {
//                 alert(err.response.data.msg);
//             }
//             return { success: false }
//         });
//         console.log(res)
      
// };

const Register = async (user) => {
    var res = {}
    try {
        const reqBody = {
            username: user.username,
            mdp: user.mdp,
            nom: user.fullname,
            email: user.email,
            tel: cleanPhone(user.tel),
            preferences: user.preferences
        };

        const resp = await client.post("/annonceur", reqBody, {
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (resp.data.error) {
            alert(resp.data.msg);
            return { success: false };
        } else {
            console.log(resp.data.data);
            localStorage.setItem("userId", resp.data.data.id);
            localStorage.setItem('username', resp.data.data.username)
            return { success: true, data: resp.data.data };
        }
    } catch (error) {
        console.error(error);
        if (error.response && error.response.data.error) {
            alert(error.response.data.msg);
        }
        return { success: false };
    }


};

export default Register