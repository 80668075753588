import React, { useEffect } from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Box, Button, Typography } from "@mui/material";
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import { Tables } from "../../data/table";
import Table from "../global/Table";
import "./css/Dashboard.css";
import { Link, useLoaderData } from "react-router-dom";
import client from "../../utils/axiosClient";

const Dashboard = () => {

  // recupération des données
  const dashboardData = useLoaderData();


  // les variables 
  const maxNumber = 6;

  // changement du titre de la page
  useEffect(() => {
    document.title = 'WescanGift - Tableau de bord';
  }, []);

  // les fonctions


  // le rendu
  return (
        <Box
          className="DashboardMain"
          p="4rem"
          paddingTop="3rem"
          sx={{
            backgroundColor: "#F9F9F9",
          }}
          display="grid"
          gridTemplateRows="14rem auto"
          gap="2rem">
          <Box className="StatPresenter">
            <Box
              id="vecteurBox1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="100%">
              <Link to="/annonces">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color="white">
                  <Typography variant="h4" fontSize="3rem">
                    2500
                  </Typography>
                  <Typography variant="span" fontSize="1rem">
                    Vues d’annonces
                  </Typography>
                  <Typography variant="span" className="statPresenterButton">
                    Voir
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Box
            id="vecteurBox2"
              borderLeft="1px solid white"
              borderRight="1px solid white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="100%">
              <Link to="/annonces/new">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color="white">
                  <Typography variant="h4" fontSize="3rem">
                  {dashboardData.data.filter(annonce => annonce.status === 1).length}
                  </Typography>
                  <Typography variant="span" fontSize="1rem">
                    Annonces en cours
                  </Typography>
                  <Typography variant="span" className="statPresenterButton">
                    Créer
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Box
              id="vecteurBox3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="100%">
              <Link to="/credit">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color="white">
                  <Typography variant="h4" fontSize="3rem">
                    2300
                  </Typography>
                  <Typography variant="span" fontSize="1rem">
                    Crédits restant
                  </Typography>
                  <Typography variant="span" className="statPresenterButton">
                    Ajouter
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Box>
          <Box className="TableContainer" height="100%">
            <Table data={dashboardData.data} type="Annonces" maxNumber={maxNumber} />
          </Box>
        </Box>
  );
};

export default Dashboard;


export const dashboardLoader = async () => {

  var data = {}
  const id = 8 ;
      try {
        const response = await  client.get(`/annonceur/${id}/statistiques/annonces`);
        const jsonData = response;
        data = jsonData.data;
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        data = error.response;
      }

  return data || { data: []}
};

