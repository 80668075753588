import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { TextField } from "@mui/material";
import { ReactComponent as SelectPref } from "../../../assets/selectPref.svg";
import { AuthContext } from "../../../context/AuthContext";
import '../css/login.css';

const SignIn = () => {

    const {user, setUser, userLogin} = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
     
        await userLogin(user)

       if ( localStorage.getItem('token') ) {
        setIsLoading(false);
        navigate('/')
       } else {
        setIsLoading(false)
        setErrorMessage('Erreur ! Veuillez vérifiez vos informations.') 
       }
    };

    //const gotoSignUpPage = () => navigate("/preference");
const gotoSignUpPage = () => window.open('https://wescan.gift/signup', '_blank');

    return (
        <div className='login__container'>
            <h2>Se connecter </h2>
            <SelectPref />
            <form className='login__form mt-4' onSubmit={handleSubmit}>
                <label htmlFor='username'>Identifiant</label>
                <TextField label="Identifiant" value={user.username} onChange={(e) => setUser({...user, 'username' : e.target.value}) } margin="normal" variant="outlined" color="primary" type="text" id="username" name="username" required/>

                <label htmlFor='mdp'>Password</label>
                <TextField label="Mot de passe" value={user.mdp} onChange={(e) => setUser({...user, 'mdp' : e.target.value}) } margin="normal" variant="outlined" color="primary" type="password" id="mdp" name="mdp" required sx={{ borderColor: '#000000' }} />
        
        <label> {errorMessage} </label>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="my-2" >
    <ClipLoader loading={isLoading} size={30} />
</div>                <button className='loginBtn' type="submit">SE CONNECTER</button>
                <p>
                    Vous n'avez pas de compte ?
                    <span className='link' onClick={gotoSignUpPage}>
                        S'inscrire
                    </span>
                </p>
            </form>
        </div>
    );
};

export default SignIn;