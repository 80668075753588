import { React, useState } from "react";
import "./TopBar.css";
import { Box, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const NotificationsDiv = ({ notifications }) => {
  return (
    <Box className="notificationsContainer">
      <Box className="notificationsHeader">
        <Typography variant="h6" fontSize={12}>
          Notifications
        </Typography>
        <DeleteOutlineIcon />
      </Box>
      {notifications.map((note, index) => {
        return (
          <>
            <Box className="notif" key={index}>
              <Typography variant="span" fontSize={10} className="notifMessage">
                {note.message}
              </Typography>
              <ClearIcon />
            </Box>
            <Divider />
          </>
        );
      })}
    </Box>
  );
};
const TopBar = ({ userProfile, userName, notifications }) => {
  const [openNotif, setOpenNotif] = useState(false);
  return (
    <Box className="TopBar" height="100%" width="96%">
      <Box className="TopBarRight">
        <Box
          className="notifications"
          onClick={() => setOpenNotif((prev) => !prev)}>
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent=" "
            variant="dot"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#FF3D00",
                top: " 29%",
                right: "32%",
              },
            }}>
            <NotificationsNoneOutlinedIcon fontSize="large" />
          </Badge>
        </Box>
        <Avatar alt="user image" src={userProfile} />
        <Typography variant="span">{userName}</Typography>
      </Box>
      {openNotif && <NotificationsDiv notifications={notifications} />}
    </Box>
  );
};

export default TopBar;
