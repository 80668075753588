import React from 'react';
import { Outlet, useLocation } from 'react-router';
import SideBar from "./global/SideBar";
import { Box } from "@mui/material";
import AvatarImage from "../assets/avatar.jpeg"
import TopBar from './global/TopBar';
import { Notifications } from "../data/notifs";
import "./root.css";

const Root = () => {

  const location = useLocation();

  // Ne pas afficher la barre latérale et les autres éléments sur la page de connexion
  if (location.pathname === '/login') {
    return <Outlet />;
  }

  return (
    <Box
      className="Dashboard"
      sx={{
        display: "flex",
        height: "100vh",
      }}>
      <SideBar
        userProfile={AvatarImage}
        userName="John Daniel"
        userEmail="johndaniel@gmail.com"
      />
      <Box
        className="DashboardContent"
        sx={{
          height: "100%",
          display: "grid",
          gridTemplateRows: "3rem auto",
          gap: "0rem",
        }}>
        <TopBar
          userName="John Daniel"
          userProfile={AvatarImage}
          notifications={Notifications}
        />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Root;