import { React, useContext } from "react";
import "./SideBar.css";
//import "react-pro-sidebar/dist/css/styles.css";
import { Link,useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Logo from "../../assets/logo.png";
import Avatar from "@mui/material/Avatar";
import { AuthContext } from "../../context/AuthContext";

// const MenuItem = ({ title, icon, to, selected, setSelected }) => {
//   if (selected === title) {
//     return (
//       <Link to={to}>
//         <Box className="MenuItem menuSelected">
//           <Box className="menuTitle">
//             {icon}
//             <Typography variant="span">{title}</Typography>
//           </Box>
//           <Box className="selected">
//             <ExpandMoreOutlinedIcon />
//           </Box>
//         </Box>
//       </Link>
//     );
//   } else {
//     return (
//       <Link to={to}>
//         <Box className="MenuItem">
//           <Box className="menuTitle">
//             {icon}
//             <Typography variant="span">{title}</Typography>
//           </Box>
//           {selected === title  && (
//             <Box className="selected">
//               <ExpandMoreOutlinedIcon />
//             </Box>
//           )}
//         </Box>
//       </Link>
//     );
//   }
// };




const MenuItem = ({ title, icon, to }) => {
  const location = useLocation();
  const selected = location.pathname === to;

  return (
    <Link to={to}>
      <Box className={`menuItem ${selected ? 'menuSelected' : ''}`}>
        <Box className="menuTitle">
          {icon}
          <Typography variant="span">{title}</Typography>
        </Box>
        {selected && (
          <Box className="selected">
            <ExpandMoreOutlinedIcon />
          </Box>
        )}
      </Box>
    </Link>
  );
};



const SideBar = ({ selected, userProfile, userName, userEmail }) => {

  const { logout } = useContext(AuthContext);

  return (
    <Box className="SideBar" marginTop={4}>
      <Box className="TopSideBar">
        {/* LOGO */}
        <Box className="logo" marginLeft={3}>
          <img src={Logo} alt="logo" height="75%" width="75%" />
        </Box>
        {/* MENU */}
        <Box
          className="menu"
          marginTop={10}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}>
          <MenuItem
            title="Aperçue"
            icon={<GridViewOutlinedIcon fontSize="large" />}
            to="/"
            selected={selected}
          />
          <MenuItem
            title="Annonces"
            icon={<CampaignOutlinedIcon fontSize="large" />}
            to="/annonces"
            selected={selected}
          />
          <MenuItem
            title="Evénements"
            icon={<EventAvailableOutlinedIcon fontSize="large" />}
            to="/evenements"
            selected={selected}
          />
          <MenuItem
            title="Crédit"
            icon={<AccountBalanceWalletOutlinedIcon fontSize="large" />}
            to="/credit"
            selected={selected}
          />
          <MenuItem
            title="Paramètres"
            icon={<SettingsOutlinedIcon fontSize="large" />}
            to="/parametres"
            selected={selected}
          />
        </Box>
      </Box>

      {/* LOGOUT */}
      <Box className="logout">
        <Box marginLeft="3rem">
          <Typography variant="h6" fontSize={15}>
            Profile
          </Typography>
        </Box>
        <Box className="profile">
          <Avatar alt="user image" src={userProfile} />
          <Box className="profileDetails">
            <Typography variant="h6" fontSize={12}>
              {userName}
            </Typography>
            <Typography variant="span" fontSize={12}>
              {userEmail}
            </Typography>
          </Box>
        </Box>

     <Box className="logoutButton" style={{ cursor: "pointer" }} onClick={() => logout()}>
    <LogoutOutlinedIcon />
    <Typography variant="span">Déconnexion</Typography>
</Box>
      </Box>
    </Box>
  );
};

export default SideBar;
