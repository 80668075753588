import React from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import { Box, Button, Typography, ButtonGroup } from "@mui/material";
import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import { Tables } from "../../data/table";
import Table from "../global/Table";
import { Link, useLoaderData } from "react-router-dom";
import client from "../../utils/axiosClient";

const Annonces = () => {

  const annonces = useLoaderData();

  const maxNumber = 9;
  return (
        <Box
          className="DashboardMain"
          p="4rem"
          paddingTop="3rem"
          sx={{
            backgroundColor: "#F9F9F9",
          }}
          display="grid"
          gridTemplateRows="2rem auto"
          gap="2rem">
          <Box>
          
          <ButtonGroup variant="contained" style={{
            height: '100%',
          }}
            aria-label="outlined primary button group">
            <Link to="/annonces/new"> <Button target='_blank' >  Créer une annonce </Button> </Link>
            <Link to="/gagnants">  <Button style={{ background: '#EFEFFD' }} className='h-100 text-primary'  > Liste des gagnants</Button></Link>
            <Link to="/evenements/new">  <Button style={{ background: '#EFEFFD' }} className='h-100 text-primary'  > Créer un événement</Button></Link>

          </ButtonGroup>
          </Box>
          <Box className="TableContainer" height="100%">
            <Table data={annonces.data} type="Annonces" maxNumber={maxNumber} />
          </Box>
        </Box>
  );
};

export default Annonces;


export const annoncesLoader = async () => {

  var data
  const id = 8 ;
      try {
        const response = await  client.get(`/annonceur/${id}/statistiques/annonces`);
        const jsonData = response;
        data = jsonData.data;
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        data = error.response;
      }

  return data
};
