import React, {useEffect, useState} from "react";
import SideBar from "../global/SideBar";
import TopBar from "../global/TopBar";
import {Box, Button, Input, Typography , InputLabel, MenuItem, FormControl, Select, FormLabel } from "@mui/material";

import AvatarImage from "../../assets/avatar.jpeg";
import { Notifications } from "../../data/notifs";
import Modal from 'react-modal';
import "./css/Dashboard.css";
import dayjs from "dayjs";

import { useLoaderData, useLocation, useNavigate } from "react-router";
import client from "../../utils/axiosClient";
import { getQueryParams } from "../../utils/function";
import { post } from "../../utils/ApiCrud";


/* import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar"; */

const Credit = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCredit, setModalCredit] = useState(false);
  const idUser = localStorage.getItem('userId');

  const creditInfos = useLoaderData();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search);
  const [ creditAmount, setCreditAmount ] = useState(0);

  const paymentId = queryParams['paymentId'];
  const paymentStatus = queryParams['paymentStatus'];


  // si le paiement est fait, faire la requête pour enregistrer l'achat et créer la borne
  useEffect(() => {

    if (paymentId && paymentStatus === 'success') {
      
      console.log(paymentStatus, paymentId);
      post(`/annonceur/${idUser}/addcredit`, { 'credit': creditAmount ? creditAmount : 0})
        .then((res) => {
          console.log(res);
          alert("Paiement effectué avec succès. Votre crédit a été ajouté.");
        }).catch((err) => {
          console.log(err);
        })

    } else if (paymentStatus === 'failed' || paymentStatus === 'cancelled') {
      console.log("payment failed");
      alert("Paiement échoué. Veuillez réessayer.");
    }
  }, [paymentStatus, paymentId]);


  const initiatePayment = () => {

    // payWithQosicCheckout( { nom : user.nom, amount : amount,  } );

    post(`/annonceur/${idUser}/initaddcredit`, { 'credit': creditAmount ? creditAmount : 0 })
      .then((res) => {
        console.log(res.data);

        //redirect to payment page
        window.open(res.data.data.checkout_url);

      }).catch((err) => {
        console.log(err);
      })
  }

  return (
        <Box
          className="DashboardMain"
          p="4rem"
          paddingTop="3rem"
          sx={{
            backgroundColor: "#F9F9F9",
          }}
          display="grid"
          gridTemplateRows="14rem 8rem auto"
          gap="2rem">
          <Box display="flex" bgcolor="#ff3d00" borderRadius="15px">
            <Box
              id="vecteurBox1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="80%"
              borderRight="1px solid white">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                color="white">
                <Typography variant="h4" fontSize="1.5rem">
                  Dépensé ce mois
                </Typography>
                <Typography variant="span" fontSize="4rem">
                  2500
                </Typography>
                <Typography variant="span" fontSize="1rem">
                  CFA
                </Typography>
              </Box>
            </Box>
            <Box
              id="vecteurBox2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              width="80%">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                color="white">
                <Typography variant="h4" fontSize="1.5rem">
                  Crédit restant
                </Typography>
                <Typography variant="span" fontSize="4rem">
                  2300
                </Typography>
                <Typography variant="span" fontSize="1rem">
                  CFA
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            bgcolor="#7CA1D2"
            borderRadius="10px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            onClick={() => setModalCredit(!modalCredit)}
            color="white">
            <Typography variant="h4" fontWeight="bold" fontSize="1.5rem">
              Augmenter votre budget et afficher plus !
            </Typography>
            <Typography variant="h6" className="statPresenterButton">
              Ajouter
            </Typography>

          

          </Box>
          <Box bgcolor="white" display="flex">
            {/* <DateRangeCalendar
              defaultValue={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
              //slots={{ day: DateRangePickerDay }}
            /> */}
          </Box>
             {/* Si le paiement est initié mais le résultat n'est pas autre que success alors on affiche le modal d'erreur   */}
       
             <Modal
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.35)' // This will give a dark background with 75% opacity
                },
                content: {
                  // color: 'lightsteelblue', // This will change the text color
                  width: '50%', // This will change the width of the modal
                  // height: '50%', // This will change the height of the modal
                  marginLeft: 'auto', // These two lines will center the modal
                  marginRight: 'auto',
                  borderRadius: '10px', // This will round the corners of the modal
                  padding: '20px' // This will add padding inside the modal
                }
              }}
              isOpen={modalCredit}
              onRequestClose={() => setModalCredit(!modalCredit)}
              shouldCloseOnOverlayClick={true} // This allows the modal to close when clicking outside
            >

<Box
            bgcolor="white"
            height="100%"
            paddingBottom="4rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >

<Box
              paddingTop="4rem"
              paddingLeft="2rem"
              display="flex"
              flexDirection="column"
              gap="1.5rem">
              <FormControl sx={{ minWidth: '100%' }} >
                        <FormLabel htmlFor="limite">Acheter des crédits WeScanGift</FormLabel>
                        <Input
                          style={{ width: "100%", borderRadius: "5px", marginTop: "0" }}
                          className="input"
                          name="creditAmount"
                          id="creditAmount"
                          type="number"
                          min="0"
                          value={creditAmount ? creditAmount : 0}
                          placeholder="Ex: 1000"
                          helperText="Un solde pour financer vos annonces"
                          onChange={(e) => { setCreditAmount(e.target.value) }}
                        />
                      </FormControl>
        
                  <Box >
                 
                    <Box
                      sx={{ cursor: "pointer", width: "100%" }}
                      borderRadius="5px"
                      bgcolor="#FF3D00"
                      p="0.25rem 2rem"
                      color="white"
                      onClick={initiatePayment}
                      className="text-center"
                    >
                      <Typography variant="h6" className="text-center py-1" fontSize={18}>
                        Payer {creditAmount} CFA
                      </Typography>
                    </Box>
                  </Box>

          
            </Box>

            </Box>


            </Modal> 
        <Modal
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.35)' // This will give a dark background with 75% opacity
            },
            content: {
              // color: 'lightsteelblue', // This will change the text color
              width: '50%', // This will change the width of the modal
              // height: '50%', // This will change the height of the modal
              marginLeft: 'auto', // These two lines will center the modal
              marginRight: 'auto',
              borderRadius: '10px', // This will round the corners of the modal
              padding: '20px' // This will add padding inside the modal
            }
          }}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(!modalIsOpen)}
          shouldCloseOnOverlayClick={true} // This allows the modal to close when clicking outside
        >
          <Box
            bgcolor="white"
            height="100%"
            paddingBottom="4rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 mb={2}> Oops 🫢! </h1> {/* Add space below the h1 */}
            <Typography
              variant="p"
              fontSize={24}
              className="text-center"
              mb={2}>
              Il semble y avoir eu un échec lors du paiement. Veuillez vérifier les informations et réessayer.
            </Typography> {/* Add space below the Typography */}

            <Box
              sx={{ cursor: "pointer" }}
              borderRadius="5px"
              bgcolor="#0E61CB"
              p="0.25rem 2rem"
              color="white"
              onClick={() => {
                setModalIsOpen(!modalIsOpen);
                navigate('/evenements/new', { replace: true });

              }}
              className="text-center"
            >
              <Typography variant="h6" className="text-center py-1" fontSize={18}>
                Okay
              </Typography>
            </Box>
          </Box>

        </Modal>

        </Box>
  );
};

export default Credit;


export const creditLoader = async () => {

  var data
  const id = 8 ;
      try {
        const response = await  client.get(`/annonceur/${id}/solde`);
        const jsonData = response;
        data = jsonData.data;
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        data = error.response;
      }

  return data
};
