export async function FileToBase64(file) {
  // We Process Avatar
  if (typeof file === "string") {
    // we base64 encode the string
    const base64File = {
      file: btoa(file),
      type: "text/plain",
    };
    return base64File;
  }

  // FileReader type case
  if (file !== null) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const theFile = reader.result;
        const base64File = {
          file: theFile.split("base64,")[1],
          type: theFile.split("/")[0].split("data:")[1],
        };
        if (base64File.file === "") {
          reject({ status: 406, message: "file is empty" });
          return;
        }
        resolve(base64File);
      };

      reader.onerror = function () {
        reject({ status: 406, message: "reader error" });
      };
    });
  }

  const base64File = {
    file: "",
    type: "",
  };

  return base64File;
}

export function cleanPhone(phoneNumber) {
  return phoneNumber.replace(/\+|\s/g, ''); // Utilisation de replace() avec une expression régulière
}

export async function paiementMomo(prix, number) {
  const data = {
    amount: prix,
    phone: cleanPhone(number),
  };
  try {
    const response = await fetch("https://api.momo.bj/v1/transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const data_1 = await response.json();
    console.log(data_1);
    return data_1;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function paiementFlooz(prix, number) {
  const data = {
    amount: prix,
    phone: cleanPhone(number),
  };
  try {
    const response = await fetch("https://api.flooz.bj/v1/transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const data_1 = await response.json();
    console.log(data_1);
    return data_1;
  } catch (error) {
    console.error("Error:", error);
  }
}

//check transaction status
export async function checkTransactionStatus(type, transref) {
  const data = {
    clientid: process.env.REACT_APP_QOSIC_CLIENT_ID,
    secret: process.env.REACT_APP_Q
  };
  try {
    const response = await fetch("https://api.qosic.com/v1/transaction/status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const data_1 = await response.json();
    console.log(data_1);
    return data_1;
  } catch (error) {
    console.error("Error:", error);
  }
}

//pay with qosic checkout 
export async function payWithQosicCheckout(paymentInfos) {

   // initiale body for request  ==> m = mandatory = obligatoire
   var data = {
    "type": "all", // in checkout value is mobile/card/all ==> m
    "transref": new Date().getTime(), // transref ===> m et unique
    "qosKey": "QCBJ001", // is provided by qos platform  ==> m
    "returnUrl": window.location.href , // is callback redirection with parameter transref ans status ==> m
    "amountDetails": {
        "totalAmount": paymentInfos.amount, // amount wil be pay by customer //  ==> m
        "currency": "XOF"
    },
    "saleDetails": {
        "firstName": paymentInfos.nom , // m
        "lastName":  paymentInfos.nom, // m
        "title": "Mr",  // m
        "address1": "Cotonou", // m
        "locality": "Litoral",   // m
        "postalCode": "229",  // m
        "country": "Benin",  // m
        "district": "Bj",   // m
        "email":  paymentInfos.nom.email,  // m
        "phoneNumber":  paymentInfos.tel,   // m
        "phoneType": "cel"  // m
    }
}
  try {
    const response = await fetch("http://74.208.84.251:9014/public/v1/initTransaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const data_1 = await response.json();
    console.log(data_1);
    return data_1;
  } catch (error) {
    console.error("Error:", error);
  }

}

  //get url params using react-router-dom
export  function getQueryParams(query) {
    const params = {};
    query
      .replace(/^\?/, '')
      .split('&')
      .forEach(param => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value);
      });
    return params;
  }



export async function generateQrCode(data) {
  const response = await fetch("https://api.qrcode-monkey.com//qr/custom", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: data,
      config: {
        body: "circle",
        eye: "frame13",
        eyeBall: "ball0",
        qrOptions: {
          version: "auto",
          errorCorrectionLevel: "H",
        },
        colors: {
          dark: "#1A1A1A",
          light: "#FFFFFF",
        },
      },
      size: 500,
      download: true,
      file: "png",
    }),
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function generateQrCode1(data) {
  const response = await fetch("https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" + data);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}


export const fsImageAsDataURI = async (path) => {
  const blob = await fetch(path).then((res) => res.blob());
  const reader = new FileReader();
  return await new Promise((resolve, reject) => {
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};


export async function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}


export function getBaseUrl() {
  console.log(process.env.REACT_APP_BACK_PROD_URL);
  const baseUrl = process.env.REACT_APP_NODE_ENV === "production"
     ? process.env.REACT_APP_BACK_PROD_URL
     : process.env.REACT_APP_BACK_DEV_URL;
  return baseUrl;
  }