import React, { useState, useRef, useEffect, useContext } from "react";
import "./Table.css";
import { Box, Button, Typography } from "@mui/material";
import { Filters } from "../../data/filter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import AnnonceInfos from "./AnnonceInfos";
import EventInfos from "./EventInfos";
import { DashboardContext } from "../../context/DashboardContext";

const Table = ({ data = [], type, maxNumber }) => {

  const { currentAd, setCurrentAd, currentEvent, setCurrentEvent } = useContext(DashboardContext);
  // We check the lenght of the data and if it's less than the maxNumber we set the maxNumber to the lenght of the data
  if (data.length < maxNumber) {
    maxNumber = data.length;
  }
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / maxNumber);

  const startIndex = (currentPage - 1) * maxNumber;
  const endIndex = startIndex + maxNumber;
  const currentItems = data.slice(startIndex, endIndex);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const setCurrentItem = (id) => {
    if (type === "Annonces") {
      setCurrentAd(id);
    } else {
      setCurrentEvent(id);
    }
  }
  /* const [elements, setElements] = useState(data.slice(0, maxNumber)); */
  const [filter, setFilter] = useState(Filters[0]);
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    borderRadius: "8px",
  };

  const FilterDiv = ({ filters }) => {
    return (
      <Box
        className="filtersContainer"
        width="12rem"
        p="0.5rem 1rem"
        display="flex"
        flexDirection="column"
        gap="0.5rem"
        bgcolor="white"
        borderRadius="5px"
        marginTop="1rem">
        {filters.map((filter, index) => {
          return (
            <>
              <Box
                className="filter"
                key={index}
                onClick={() => setFilter(filter.filter)}>
                <Typography
                  variant="span"
                  fontSize={15}
                  className="filterMessage">
                  {filter.filter}
                </Typography>
              </Box>
              <Divider />
            </>
          );
        })}
      </Box>
    );
  };

  return (
    <Box height="100%">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h5">Statistiques</Typography>
        </Box>
        <Box>
          <Box
            p="0.25rem 2rem"
            border="1px solid black"
            borderRadius="5px"
            display="flex"
            gap="1rem"
            id="filterSelect"
            onClick={() => setOpenFilter((prev) => !prev)}>
            <Typography variant="span">{filter.filter}</Typography>
            <ArrowDropDownIcon />
          </Box>
          {openFilter && <FilterDiv filters={Filters} />}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        className="tablefeature"
        justifyContent="space-between"
        height="100%">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Id d'{type}</th>
                <th>{type}</th>
                <th>Vues total</th>
                <th>Clique</th>
                <th>Option</th>
                 {/* {Object.keys(data[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))} */}
              </tr>
            </thead>
            <tbody>
              {
                data.length === 0 ? <center><span className="fst-italic fw-bold" >Aucune annonce pour le moment</span></center> :
             
                currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.nom}</td>
                  <td>{item.vues}</td>
                  <td>%{item.cliques / 100}</td>
                  <td>
                    <Box
                      display="flex"
                      gap="0.25rem"
                      p="0.25rem 0.5rem"
                      width="fit-content"
                      border="1px solid #e0e0e0"
                      justifyContent="center"
                      onClick={handleOpen}>
                      <VisibilityIcon fontSize="small" />
                      <Typography onClick={ (e) => setCurrentItem(item.id) } variant="span" fontSize={13} sx={ { cursor: "pointer" } } >
                        Détails
                      </Typography>
                    </Box>
                  </td>
                </tr>
              ))}
              <Modal open={open} onClose={handleClose}>
                {type === "Annonces" ? (
                  <Box sx={style}>
                    <AnnonceInfos action={handleClose} annonceId={currentAd} />
                  </Box>
                ) : (
                  <Box sx={style}>
                    <EventInfos action={handleClose}  eventId={currentEvent} />
                  </Box>
                )}
              </Modal>
            </tbody>
          </table>
        </div>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          height="10%"
          alignItems="center">
          <Typography variant="span" paddingLeft="3rem">
            {startIndex + 1}-{endIndex} affiche du total
          </Typography>
          <Box display="flex" gap="1rem" paddingRight="4rem">
            <Box
              height="3.5vh"
              width="3.5vh"
              bgcolor="#7da2d3"
              borderRadius="5px">
              <Box paddingTop="5.5px" paddingLeft="6px">
                <ArrowBackIosOutlinedIcon fontSize="small" />
              </Box>
            </Box>
            <Box
              height="3.5vh"
              width="3.5vh"
              bgcolor="#FF3D00"
              borderRadius="5px">
              <Box paddingTop="5.5px" paddingLeft="6px" color="#ffffff">
                <ArrowForwardIosOutlinedIcon fontSize="small" />
              </Box>
            </Box>
            {/* <Pagination count={totalPages} variant="outlined" shape="rounded" /> */}
          </Box>
        </Box>
      </Box>
    </Box> 
  );
};

export default Table;
