import React, {useContext} from 'react';
import { Outlet} from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import SignIn from '../page/landing/SignIn';

const OnlyLoggedUserRoute = () => {


const { isLogin, isAnonyme } = useContext(AuthContext);


    return (
      isLogin && !isAnonyme && localStorage.getItem('token') ? <Outlet /> : window.location.href = '/login'
    );
};

export default OnlyLoggedUserRoute;