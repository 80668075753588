import { Button } from "@mui/material"
import image from "../../assets/holder-ads.jpg"
import { useNavigate } from "react-router"
import React, { useEffect, useState } from 'react';
import { del, get } from "../../utils/ApiCrud";

const AnnonceInfos = ( { action, annonceId } ) => {

 async function getAnnonceInfos(annonceId) {
    // Fetch data from the API
    const response = await get(`/annonceur/${userId}/statistiques/annonces?idAnnon=${annonceId}`);
   console.log(response.data);
   return response.data[0];
}


const userId = localStorage.getItem("userId")
const navigate = useNavigate()
const [annonceIdd, setAnnonceIdd] = useState(annonceId) ;
const [annonceInfos, setAnnonceInfos] = useState() ;


 
    useEffect( () => {
     console.log( 'hello ', annonceIdd)
      getAnnonceInfos(annonceIdd).then((data) => {
        console.log(data)
        setAnnonceInfos(data)
      })
    }, [annonceIdd])

  
    const deleteAnnonce = (annonceId) => {
        // Delete the annonce from the API
        del(`/annonce/${annonceId}`)
        // Then close the modal
        action()

    }

    const modifyAnnonce = (annonceId) => {
        // go to modify annonce page
        navigate("/annonces/new/"+annonceId)
    }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-content-center bg-dark bg-opacity-50">
    <div className="w-100 max-w-4xl mx-auto bg-white rounded-lg">
      <div className="d-flex flex-row justify-content-between items-start p-5 border-b border-solid border-gray-300 rounded-t">
        <h3 className="text-3xl font-semibold">Détails annonce</h3>
        <button onClick={action} className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
          <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"> <i className="fas fa-times"></i> </span>
        </button>
      </div>
      <div className="d-flex justify-content-center" >
        <div className="w-50 px-4">
          <p className="text-lg leading-relaxed">
            <strong>Nom :</strong> {annonceInfos.nom ? annonceInfos.nom : "Nom de l'annonce"} 
            <br />
            <br />
            <strong>Nombre de vue total :</strong> {annonceInfos.vues || 0}
            <br />
            <br />
            <strong>Nombre de clique total :</strong> ${annonceInfos.cliques || 0}
            <br />
            <br />
            <strong>Fin de l’annonce :</strong> ${annonceInfos.date_fin || 0}
            <br />
            <br />
            <strong>Affichage restant :</strong>
            ${annonceInfos.limite < 0 ? 'Illimité' : annonceInfos.limite || 0}
          </p>
        </div>
        <div className="w-50 px-4">
          <div className="mb-4">
            <img
              alt={annonceInfos.nom || "Nom de l'annonce"}
              className="rounded-lg"
              height="250"
              src={image}
              style={{
                aspectRatio: "250/250",
                objectFit: "cover",
              }}
              width="250"
            />
          </div>
          <p className="text-center text-lg">Clique sur l'image pour récupérer</p>
        </div>
      </div>
      <div className="d-flex justify-content-between p-6 border-t border-solid border-gray-300 rounded-b">
        <div>
          <Button onClick={modifyAnnonce} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Modifier</Button>
        </div>
        <div>
          <Button className="bg-danger hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Supprimer</Button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AnnonceInfos