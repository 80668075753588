import { Button } from "@mui/material";
import { useRouteError, Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="text-center " 
    style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'justify',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        margin: 'auto',  // Centre horizontalement
      }}
    
    >
      <h1>Oops!</h1>
      <p>Désolé, une erreur inattendue s'est produite.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <Button as={Link} to="/">Retour à la page d'accueil</Button>   
       </div>
  );
}