import { Button } from "@mui/material"
import image from "../../assets/holder-ads.jpg"
import { useNavigate } from "react-router"
import React, { useEffect, useState } from 'react';
import { del, get } from "../../utils/ApiCrud";

const EventInfos = ( { action, eventId } ) => {

    const navigate = useNavigate()

    const [EventInfos, setEventInfos] = useState(getEventInfos(eventId))

    function getEventInfos( eventId) {
        // Fetch data from the API
        get(`/annonceur/:id/statistiques/events/${eventId}`).then((response) => {
            return response.data
        })
    }

    useEffect(() => {
      setEventInfos(getEventInfos(eventId))
    }, [eventId])

    const deleteEvent = (eventId) => {
        // Delete the annonce from the API
        del(`/events/${eventId}`)
    }


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-content-center bg-dark bg-opacity-50">
    <div className="w-100 max-w-4xl mx-auto bg-white rounded-lg">
      <div className="d-flex flex-row justify-content-between items-start p-5 border-b border-solid border-gray-300 rounded-t">
        <h3 className="text-3xl font-semibold">Détails de l'événement</h3>
        <button onClick={action} className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
          <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"> <i className="fas fa-times"></i> </span>
        </button>
      </div>
      <div className="d-flex justify-content-center" >
        <div className="w-50 px-4">
          <p className="text-lg leading-relaxed">
            <strong>Nom :</strong> {EventInfos.nom || "Nom de l'événement"}
            <br />
            <br />
            <strong>Résultats </strong>   <br />
            <strong>Vue du jour :</strong> 1200
            <br />
            <strong>Vues du jour :</strong> { EventInfos.vues_daily || 0 } | <strong>Cliques :</strong> { EventInfos.cliques_daily || 0 }
            <br />
            <strong>Total du vues :</strong> { EventInfos.vues || 0 } | <strong>Total Cliques :</strong> { EventInfos.cliques || 0 }
            <br />
            <br />
            <strong>Limites </strong>
            <strong>Max par jour:</strong> { EventInfos.max_daily || 0 } | <strong>Max total:</strong> { EventInfos.max_total || 0 }
            <br />
            <strong>Période:</strong>  { EventInfos.date_debut || 0 } - { EventInfos.date_fin || 0 }
          </p>
        </div>
        <div className="w-50 px-4">
          <div className="mb-4">
            <img
              alt={EventInfos.nom || "Nom de l'événement"}
              className="rounded-lg"
              height="250"
              src={image}
              style={{
                aspectRatio: "250/250",
                objectFit: "cover",
              }}
              width="250"
            />
          </div>
          <p className="text-center text-lg">Clique sur l'image pour récupérer</p>
        </div>
      </div>
      <div className="d-flex justify-content-center p-6 border-t border-solid border-gray-300 rounded-b">
      
        <div>
          <Button onClick={deleteEvent(eventId)} className="bg-danger hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Supprimer</Button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default EventInfos