import React from "react";
import { theme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr';
import Dashboard, { dashboardLoader } from "./views/page/Dashboard";
import Annonces, { annoncesLoader } from "./views/page/Annonces";
import Evenements, { evenementsLoader } from "./views/page/Evenement";
import Credit, { creditLoader } from "./views/page/Credit";
import Settings from "./views/page/Settings";
import NewEventPage from "./views/page/NewEvent";
import NewAnnonce from "./views/page/NewAnnonces";
import Root from "./views/Root";
import { DashboardProvider } from "./context/DashboardContext";
import ErrorPage from "./views/page/error-pages";
import { AuthProvider } from "./context/AuthContext";
import SignIn from "./views/page/landing/SignIn";
import OnlyLoggedUserRoute from "./views/global/OnlyLoggedUserRoute";
import Soon from "./views/page/soon";

const App = () => {

  const router = createBrowserRouter(

    createRoutesFromElements(


      <Route errorElement={<ErrorPage />} path="/" element={<Root />}>
        
        <Route path="/login" element={<SignIn />} />

        <Route element={<OnlyLoggedUserRoute />} >
          <Route index loader={dashboardLoader} element={<Dashboard />} />
          <Route path="/annonces" loader={annoncesLoader} element={<Annonces />} />
          <Route path="/evenements" loader={evenementsLoader} element={<Evenements />} />
          <Route path="/credit" loader={creditLoader} element={<Credit />} />
          <Route path="/bientot" element={<Soon />} />
          <Route path="/parametres" element={<Settings />} />
          <Route path="/evenements/new" element={<NewEventPage />} />
          <Route path="/annonces/new/:annonceId?" element={<NewAnnonce />} />
        </Route>


      </Route>


    ))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <AuthProvider>
        <DashboardProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <main className="Content">
              <RouterProvider router={router} />
            </main>
          </ThemeProvider>
        </DashboardProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
};

export default App;