import { createTheme } from "@mui/material/styles";

// mui theme settings
export const themeSettings = () => {
  return {
    palette: {
      orange: {
        main: "#FF3D00",
      },
      blue: {
        main: "#004AAD",
      },
      gray: {
        main: "#F5F5F5",
      },
    },
    typography: {
      fontFamily: ["Manrope", "Montserrat" , "monospace", "Inter", "sans-serif"].join(","),
      fontSize: 12,
    },
  };
};

export const theme = createTheme(themeSettings());
